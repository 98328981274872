import React, { useState, useEffect } from "react";
import moment from "moment";
import KeKeDeliveryTimeItems from "src/models/ke-ke-delivery-time-items";
import { PICKUP_CARDS } from "../../../../constants/ke-ke";
import ModalWindow from "../../../modal-window";
import PickUpCards from "./pickup-cards/pickup-cards";
import DatePicker from "../../../date-picker";
import TimeSchedule from "../../../time-schedule/time-shedule";
import FormGroup from "../../../form-group";
import Button from "../../../button";
import { EXTRA_PRICE_KE_KE } from "../../../../constants/price";
import styles from "./pickup-modal-window.module.scss";
import {
  getFilteredTimeWindows,
  getTimeItems,
} from "src/helpers/additionalFuctions";

function PickUpModalWindow({
  isOpen,
  onOpenToggle,
  deliveryDateChange,
  onTimeChange,
  defaultCard,
  confirmButtonClick,
  deliveryTime,
  isDeliveredToday,
  setInitialState,
  defaultDay,
  additionalPrice,
  setAdditionalPrice,
  deliveryTimeChange,
}) {
  const [selectedCard, setCard] = useState(defaultCard);
  const [isDisabled, setIsDisabled] = useState(false);
  const [newTimeItems, setNewTimeItems] = useState(
    getTimeItems(isDeliveredToday)
  );

  useEffect(() => {
    const disabled = (function () {

      if (selectedCard.id === 3 && !deliveryTime) {
        return true;
      }
      if (!isDeliveredToday) {
        return true;
      }
      return false;
    })();
    setIsDisabled(disabled);
  }, [selectedCard, isDeliveredToday, deliveryTime]);


  const onCardSelect = (card) => {
    if (card.periodDay === "Dnes") {
      setAdditionalPrice({
        ...additionalPrice,
        express: { active: false },
        dayOff: { active: false },
        extraTime: { active: false },
      });
    }

    if (card.periodDay === "Expres") {
      setAdditionalPrice({
        ...additionalPrice,
        express: { active: true, extraprice: EXTRA_PRICE_KE_KE },
        dayOff: { active: false },
        extraTime: { active: false },
      });
    }

    if (card.periodDay === "Iný deň") {
      setAdditionalPrice({
        ...additionalPrice,
        express: { active: false },
        extraTime: { active: false },
      });
    }
    setInitialState();
    setCard(card);
  };

  const onChangeDay = (date) => {
    let isDayOff = date.getDay() === 6 || date.getDay() === 0;
    const presentDay = new Date().getDate();
    const choosenDate = date.getDate();
    if (presentDay !== choosenDate) {
      setNewTimeItems(getTimeItems(false));
    } else {
      setNewTimeItems(getTimeItems(true));
    }
    if (isDayOff) {
      setAdditionalPrice({
        ...additionalPrice,
        dayOff: { active: true, extraprice: EXTRA_PRICE_KE_KE },
        express: { active: false },
      });
    }
    deliveryDateChange(moment(date));
  };
  return (
    <>
      <ModalWindow
        title="Dátum a čas vyzdvihnutia v Košiciach"
        isOpen={isOpen}
        onToggle={onOpenToggle}
        body={
          <>
            <div className={styles.pickupWrap}>
              {PICKUP_CARDS &&
                PICKUP_CARDS.map((card, index) => (
                  <PickUpCards
                    key={index}
                    onCardSelect={() => {
                      onCardSelect(card);
                    }}
                    card={card}
                    activeCard={selectedCard.id}
                  />
                ))}
            </div>

            {selectedCard.id === 1 && (
              <FormGroup className={styles.itemWrap}>
                <TimeSchedule
                  onTimeSelect={onTimeChange}
                  items={newTimeItems}
                  selectedItem={deliveryTime && deliveryTime.id}
                  isFiltered={isDeliveredToday}
                />
              </FormGroup>
            )}

            {selectedCard.id === 3 && (
              <FormGroup className={styles.itemWrap}>
                <div className={styles.selectDataWrap}>
                  <span className={styles.selectDataTitle}>Vyberte dátum</span>
                  <DatePicker
                    onChange={(date) => onChangeDay(date)}
                    defaultDay={defaultDay}
                  />
                </div>
                <TimeSchedule
                  selectedItem={deliveryTime && deliveryTime.id}
                  onTimeSelect={onTimeChange}
                  isFiltered={isDeliveredToday}
                  items={newTimeItems}
                />
              </FormGroup>
            )}
            <Button
              color="green"
              type="submit"
              onClick={() => {
                confirmButtonClick(selectedCard);
              }}
              disabled={false}
            >
              Uložiť
            </Button>
          </>
        }
      />
    </>
  );
}

export default PickUpModalWindow;
