import React, { useEffect, useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import ManuallyTimeWindow from "../../manually-time-window";
import {
  addDaysToDate,
  formatDate,
  getDayNumber,
  getNextWeekDay,
  getHours,
  isItAWeekEndDay,
} from "../../../helpers/additionalFuctions";
import ManuallyTimeWindowMobile from "../../manually-time-window/manual-time-window-mobile/manual-time-window-mobile";
import { FRIDAY } from "../../../constants/calendars";
import {
  today,
  KE_KE_DELIVERY_DAY_END,
  PERIOD_WORKING_HOURS,
} from "../../../constants/calendars";
import { breakPointsForKe_Ke } from "../../../constants/ke-ke";
import {isEndOfDay} from "../../../helpers/helpersFunctions";

function KeKeDateSelector({
  setAdditionalPrice,
  additionalPrice,
  errorNotChoosenTimePickup,
  setErrorNotChoosenTimePickup,
}) {
  const { control, getValues } = useFormContext();
  const [width, setWidth] = useState(window.innerWidth);
  const isDeliveredToday =
    !isItAWeekEndDay() && !isEndOfDay();
  const defaultDate = () => {
    if (isDeliveredToday && !isItAWeekEndDay()) {
      return today;
    }

    if (getDayNumber() > FRIDAY) {
      return getNextWeekDay(1);
    }
    return addDaysToDate(1);
  };
  const defaultTime = null;
  const defaultLable = null;

  const deliveryDate = useController({
    name: "deliveryDate",
    defaultValue: defaultDate(),
    control,
  });
  const deliveryTime = useController({
    name: "deliveryTime",
    defaultValue: defaultTime,
    control,
  });

  const pickUpLableTime = useController({
    name: "pickUpLableTime",
    defaultLable,
    control,
    defaultValue: PERIOD_WORKING_HOURS,
  });

  useEffect(() => {
    const listener = () => {
      setWidth(window.innerWidth);
    };
    addEventListener("resize", listener);

    return () => {
      removeEventListener("resize", listener);
    };
  }, []);

  const getSelectedLabel = () => {
    return `${formatDate(deliveryDate.field.value, "DD.MM.yy")} ${
      deliveryTime.field.value ? deliveryTime.field.value.label : ""
    }`;
  };

  const deliveryTimeChange = (item, lable) => {
    deliveryTime.field.onChange(item);
    pickUpLableTime.field.onChange(lable);
  };
  const deliveryDateChange = (date) => {
    deliveryDate.field.onChange(date);
  };

  const setInitialState = () => {
    deliveryTime.field.onChange(defaultTime);
    deliveryDate.field.onChange(defaultDate());
  };

  return (
    <>
      {width > breakPointsForKe_Ke.tablet ? (
        <ManuallyTimeWindow
          isDeliveredToday={isDeliveredToday}
          deliveryTimeChange={deliveryTimeChange}
          deliveryDateChange={deliveryDateChange}
          setInitialState={setInitialState}
          getSelectedInfo={getSelectedLabel}
          defaultDay={defaultDate()}
          additionalPrice={additionalPrice}
          setAdditionalPrice={setAdditionalPrice}
          errorNotChoosenTimePickup={errorNotChoosenTimePickup}
          setErrorNotChoosenTimePickup={setErrorNotChoosenTimePickup}
        />
      ) : (
        <ManuallyTimeWindowMobile
          additionalCost={getValues().additional}
          isDeliveredToday={isDeliveredToday}
          deliveryDate={deliveryDate.field.value}
          deliveryTime={deliveryTime.field.value}
          deliveryTimeChange={deliveryTimeChange}
          deliveryDateChange={deliveryDateChange}
          setInitialState={setInitialState}
          defaultDay={defaultDate()}
          selectedLabel={getSelectedLabel()}
          additionalPrice={additionalPrice}
          setAdditionalPrice={setAdditionalPrice}
          errorNotChoosenTimePickup={errorNotChoosenTimePickup}
          setErrorNotChoosenTimePickup={setErrorNotChoosenTimePickup}
        />
      )}
    </>
  );
}

export default KeKeDateSelector;
