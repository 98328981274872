import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import classes from "./time-window-card.module.scss";

const TimeWindowCard = function ({
  periodDay,
  active,
  description,
  icon,
  additionalCost,
  week = false,
  showCost = true,
  arrayKey,
  changeCardActive,
  disabled = false,
}) {
  return (
    <div
      onClick={() => {
        if(!disabled) {
          changeCardActive(arrayKey);
        }
      }}
      className={
        classNames(classes.card, { [`${classes.active}`]: active }, { [`${classes.disabled}`]: disabled })
      }
    >
      <div className={classes.title}>
        <h3>{periodDay}</h3>
        <div className={classes.icon}>{icon}</div>
      </div>
      <p className={classes.description}>{description}</p>
      {showCost && (
        <h3 className={additionalCost ? classes.cost : classes.cene}>
          {additionalCost ? `+${additionalCost} €` : "V cene"}
        </h3>
      )}
    </div>
  );
};

TimeWindowCard.propTypes = {
  arrayKey: PropTypes.number,
  changeCardActive: PropTypes.func,
};
TimeWindowCard.defaultProps = {
  arrayKey: PropTypes.number,
  changeCardActive: PropTypes.func,
};

export default TimeWindowCard;
