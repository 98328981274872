import React, { useState } from "react";
import classes from "../manually-time-window.module.scss";
import TimeWindowCard from "../../time-window-card";
import DatePicker from "../../date-picker";
import Select from "../../select";
import moment from "moment";
import { getTimeItems } from "../../../helpers/helpersFunctions";
const NotWeekDay = ({
  cards,
  changeActiveCard,
  isInyden,
  isDnes,
  onTimeChange,
  timeItems,
  specificTimeItems,
  defaultDay,
  checkIsDayOff,
  deliveryDateChange,
}) => {
  const [newTimeItems, setNewTimeItems] = useState(timeItems);
  const [defaultTimeItem, setDefaultTimeItem] = useState(
      timeItems.length > 1 ? specificTimeItems[0] : timeItems[0]
  );
  const onChangeDay = (date) => {
    const presentDay = new Date().getDate();
    const choosenDate = date.getDate();
    if (presentDay !== choosenDate) {
      setNewTimeItems(getTimeItems(false));
    } else {
      setNewTimeItems(timeItems);
    }
    setDefaultTimeItem(timeItems.length > 1 ? specificTimeItems[0] : timeItems[0]);
    deliveryDateChange(moment(date));
  };
  return (
    <>
      <div className={classes.cards}>
        {cards.map((card, key) => (
          <TimeWindowCard
            {...card}
            changeCardActive={changeActiveCard}
            arrayKey={key}
            key={card.id}
          />
        ))}
      </div>

      {isInyden && (
        <div className={classes.timeWindowSection}>
          <div className={classes.date}>
            <p>Zvoľte dátum</p>
            <DatePicker
              defaultDay={defaultDay}
              onChange={(date) => onChangeDay(date)}
              checkIsDayOff={checkIsDayOff}
            />
          </div>
          <div className={classes.timeWindow}>
            <p>Čas vyzdvihnutia a doručenia</p>
            <Select
              onChange={onTimeChange}
              className={classes.select}
              items={newTimeItems}
              initValue={defaultTimeItem}
            />
          </div>
        </div>
      )}

      {isDnes && (
        <div className={classes.timeWindowSection}>
          <div className={classes.timeWindow}>
            <p> Čas vyzdvihnutia a doručenia</p>
            <Select
              className={classes.select}
              onChange={onTimeChange}
              items={timeItems}
              initValue={defaultTimeItem}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default NotWeekDay;
