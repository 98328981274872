import React from "react";
import classNames from "classnames";
import styles from "./pickup-cards.module.scss";

const PickUpCards = ({ card, onCardSelect, activeCard }) => (
  <div
    role="button"
    onClick={() => {
        if(card && !card.disabled) {
            onCardSelect(card);
        }
    }}
    className={classNames(styles.card, {
      [styles.card__active]: activeCard === card.id, [styles.disabled]: card && card.disabled
    })}
  >
    <div className={styles.icon}>{card.icon}</div>

    <div className={styles.content}>
      <div className={styles.titleWrap}>
        <h3 className={styles.title}>{card.periodDay}</h3>
        {card.additionalCost && (
          <h3 className={styles.additionalCost}>+{card.additionalCost}</h3>
        )}
      </div>
      <p className={styles.description}>{card.description}</p>
    </div>
  </div>
);

export default PickUpCards;
