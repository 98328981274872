import React, { useEffect, useState } from "react";
import { PICKUP_CARDS, weekDatCardTimeWindows } from "src/constants/ke-ke";
import moment from "moment";
import CardForm from "../card-form";
import InfoIconOrange from "../../icons/ke-ke/info-icon-orange";
import DeliveryTime from "../../models/delivery-time";

import LongTimeWindows from "../../models/long-time-windows";
import NotWeekDay from "./not-week-day/not-week-day";
import { EXTRA_PRICE_KE_KE } from "../../constants/price";
import classes from "./manually-time-window.module.scss";
import {
  KE_KE_DELIVERY_DAY_END,
  START_WORKING_HOUR,
  PERIOD_WORKING_HOURS,
} from "../../constants/calendars";
import {
  getFilteredTimeWindows,
  getTimeItems,
  getHours,
  isItAWeekEndDay,
  putTime,
  momentToDate,
  addDaysToDate,
} from "../../helpers/helpersFunctions";

function ManuallyTimeWindow({
  deliveryTimeChange,
  deliveryDateChange,
  isDeliveredToday,
  defaultDay,
  setAdditionalPrice,
  additionalPrice,
  errorNotChoosenTimePickup,
  setErrorNotChoosenTimePickup,
}) {
  const [cards, setCards] = useState(PICKUP_CARDS);
  const [weekDayCards, setWeekDayCards] = useState(weekDatCardTimeWindows);
  const [isWeekDay, setIsWeekDay] = useState(false);
  const [isDnes, setIsDnes] = useState(false);
  const [isExpres, setIsExpres] = useState(false);
  const [isInyden, setIsInyden] = useState(false);
  const timeItems = getTimeItems(isDeliveredToday);
  const specificTimeItems = getFilteredTimeWindows(isDeliveredToday).map(
    (value) => new LongTimeWindows(value)
  );
  useEffect(() => {
    setIsWeekDay(isItAWeekEndDay());
  }, []);

  const changeActiveCard = (key) => {
    for (let i = 0; i < cards.length; i++) {
      if (key !== i) {
        cards[i].active = false;
      } else {
        if (cards[i].periodDay === "Dnes") {
          setIsDnes(true);
          setIsExpres(false);
          setIsInyden(false);
          cards[i].active = true;
          setAdditionalPrice({
            ...additionalPrice,
            express: { active: false },
            dayOff: { active: false },
            extraTime: { active: false },
          });
          deliveryDateChange(defaultDay);
          deliveryTimeChange({}, PERIOD_WORKING_HOURS);
        }
        if (cards[i].periodDay === "Expres") {
          setIsDnes(false);
          setIsExpres(true);
          setIsInyden(false);
          cards[i].active = true;
          setAdditionalPrice({
            ...additionalPrice,
            express: { active: true, extraprice: EXTRA_PRICE_KE_KE },
            dayOff: { active: false },
            extraTime: { active: false },
          });
          const isDeliveredToday =
            !isItAWeekEndDay() && getHours() < KE_KE_DELIVERY_DAY_END - 1;

          if (isDeliveredToday) {
            deliveryTimeChange({}, moment().add(60, "minutes").format("HH:mm"));
            deliveryDateChange(defaultDay);
          } else {
            deliveryTimeChange({}, putTime(START_WORKING_HOUR));
            deliveryDateChange(addDaysToDate(1));
          }
        }
        if (cards[i].periodDay === "Iný deň") {
          setIsDnes(false);
          setIsExpres(false);
          setIsInyden(true);
          cards[i].active = true;
          setAdditionalPrice({
            ...additionalPrice,
            express: { active: false },
            extraTime: { active: false },
          });
          deliveryDateChange(defaultDay);
          deliveryTimeChange({}, PERIOD_WORKING_HOURS);
        }
      }
    }
    setErrorNotChoosenTimePickup("");
    setCards([...cards]);
  };

  const onTimeChange = (item) => {
    const cost = item.itemCost || (item.data && item.data.cost) || 0;
    if (item.itemCost || item.data.cost) {
      setAdditionalPrice({
        ...additionalPrice,
        express: { active: false },
        extraTime: { active: true, extraprice: EXTRA_PRICE_KE_KE },
      });
    } else {
      setAdditionalPrice({
        ...additionalPrice,
        express: { active: false },
        extraTime: { active: false },
      });
    }

    deliveryTimeChange(
      new DeliveryTime({
        time: item.getLabel(),
        id: item.value,
        data: item.data,
      }),
      item.label.props.children
    );
  };

  const checkIsDayOff = (date) => {
    let isDayOff = date.getDay() === 6 || date.getDay() === 0;
    if (isDayOff) {
      setAdditionalPrice({
        ...additionalPrice,
        dayOff: { active: true, extraprice: EXTRA_PRICE_KE_KE },
        express: { active: false },
      });
    } else {
      setAdditionalPrice({
        ...additionalPrice,
        dayOff: { active: false, extraprice: EXTRA_PRICE_KE_KE },
        express: { active: false },
      });
    }
  };

  return (
    <CardForm className={classes.manually}>
      <h2 className={classes.title}>
        Čas vyzdvihnutia a doručenia v Košiciach
      </h2>

      {!isDeliveredToday && !isInyden && (
        <div className={classes.desktop}>
          <div className={classes.warning}>
            <InfoIconOrange />
            <span className={classes.txt}>
              Dnes už neprijímamé nové objednávky. Najskoršie možné vyzdvihnutie
              je zajtra.
            </span>
          </div>
        </div>
      )}

      <NotWeekDay
        timeItems={timeItems}
        onTimeChange={onTimeChange}
        cards={cards}
        changeActiveCard={changeActiveCard}
        checkIsDayOff={checkIsDayOff}
        isInyden={isInyden}
        isDnes={isDnes}
        specificTimeItems={specificTimeItems}
        deliveryDateChange={deliveryDateChange}
        defaultDay={momentToDate(defaultDay)}
      />
      {errorNotChoosenTimePickup && (
        <p className={classes.errorNotTime}>{errorNotChoosenTimePickup}</p>
      )}
    </CardForm>
  );
}

export default ManuallyTimeWindow;
