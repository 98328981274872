import React, { useState } from "react";
import styles from "./manual-time-window-mobile.module.scss";
import PickUpModalWindow from "./pickup-modal-window/pickup-modal-window";
import { PICKUP_CARDS, TYPE_EXPRESS_PICKUP } from "../../../constants/ke-ke";
import InfoIconOrange from "../../../icons/ke-ke/info-icon-orange";
import DeliveryTime from "../../../models/delivery-time";
import { EXTRA_PRICE_KE_KE } from "../../../constants/price";
import moment from "moment";
import {
  putTime,
  isItAWeekEndDay,
  getHours,
  formatDate,
  momentToDate,
  _getFormatPrice,
} from "src/helpers/additionalFuctions";
import {
  KE_KE_DELIVERY_DAY_END,
  START_WORKING_HOUR,
} from "src/constants/calendars";
const getDefaultCard = (isDeliveredToday) => {
  if (isDeliveredToday) {
    return PICKUP_CARDS[0];
  }

  return PICKUP_CARDS[2];
};

function ManuallyTimeWindowMobile({
  deliveryDate,
  deliveryTime,
  deliveryTimeChange,
  isDeliveredToday,
  setInitialState,
  deliveryDateChange,
  additionalCost,
  defaultDay,
  selectedLabel,
  additionalPrice,
  setAdditionalPrice,
  errorNotChoosenTimePickup,
  setErrorNotChoosenTimePickup,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [card, setCard] = useState(getDefaultCard(isDeliveredToday));
  const [time, setTime] = useState(deliveryTime);
  const onOpenToggle = () => setIsOpen(!isOpen);

  const confirmButtonClick = (card) => {
    setCard(card);
    const cost = card.cost || time?.data?.cost || 0;

    onOpenToggle();
    if (time) {
      deliveryTimeChange(
        new DeliveryTime({
          id: time.id,
          data: time.data,
          label: time.getLabel(),
        }),
        time.getLabel()
      );
    }
    if (card.periodDay === TYPE_EXPRESS_PICKUP) {
      setErrorNotChoosenTimePickup("");
      const isDeliveredToday =
        !isItAWeekEndDay() && getHours() < KE_KE_DELIVERY_DAY_END - 1;

      if (isDeliveredToday) {
        deliveryTimeChange({}, moment().add(60, "minutes").format("HH:mm"));
      } else {
        deliveryTimeChange({}, putTime(START_WORKING_HOUR));
      }
    }
  };

  const onTimeChange = (item) => {
    if (item.cost) {
      setAdditionalPrice({
        ...additionalPrice,
        express: { active: false },
        extraTime: { active: true, extraprice: EXTRA_PRICE_KE_KE },
      });
    } else {
      setAdditionalPrice({
        ...additionalPrice,
        express: { active: false },
        extraTime: { active: false },
      });
    }
    setErrorNotChoosenTimePickup("");
    setTime(item);
  };

  return (
    <>
      <PickUpModalWindow
        isOpen={isOpen}
        onOpenToggle={onOpenToggle}
        deliveryTime={time}
        deliveryDate={deliveryDate}
        deliveryDateChange={deliveryDateChange}
        onTimeChange={onTimeChange}
        confirmButtonClick={confirmButtonClick}
        defaultCard={card}
        setInitialState={setInitialState}
        isDeliveredToday={isDeliveredToday}
        defaultDay={momentToDate(defaultDay)}
        additionalPrice={additionalPrice}
        setAdditionalPrice={setAdditionalPrice}
        deliveryTimeChange={deliveryTimeChange}
      />
      <div className={styles.timeWindowMobileWrap}>
        {!isDeliveredToday && (
          <div className={styles.message}>
            <div className={styles.iconWrap}>
              <InfoIconOrange />
            </div>
            <span className={styles.text}>
              Dnes už neprijímamé nové objednávky. Najskoršie možné vyzdvihnutie
              je zajtra.
            </span>
          </div>
        )}

        <h2 className={styles.textDateOrder}>
          Dátum a čas vyzdvihnutia v Košiciach
        </h2>

        <div className={styles.cards}>
          <div className={styles.cardOne}>
            <div className={styles.left}>
              <div className={styles.icon}>{card.icon}</div>

              <div className={styles.cardFrame}>
                <h3>{card.periodDay}</h3>
                <p className={styles.description}>{selectedLabel}</p>
                {additionalCost > 0 && (
                  <p className={styles.price}>
                    +{_getFormatPrice(additionalCost)} €
                  </p>
                )}
              </div>
            </div>

            <a
              role="presentation"
              onClick={onOpenToggle}
              className={styles.textChange}
            >
              Zmeniť
            </a>
          </div>
        </div>

        <div className={styles.deliveryDescription}>
          <p className={styles.textShipment}>
            Zásielka bude doručená{" "}
            <span className={styles.textTomorrow}>
              {formatDate(deliveryDate)}
            </span>
          </p>
        </div>
        {errorNotChoosenTimePickup && (
          <p className={styles.errorNotTime}>{errorNotChoosenTimePickup}</p>
        )}
      </div>
    </>
  );
}

export default ManuallyTimeWindowMobile;
