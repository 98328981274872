import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { useForm, FormProvider } from "react-hook-form";
import KeKeDateSelector from "../../components/forms/ke-ke-date-selector/index";
import Page from "../../components/page";
import Header from "../../components/header";
import Footer from "../../components/footer";
import GoHome from "../../components/go-home";
import Button from "../../components/button";
import classNames from "./ke-ke.module.scss";
import InfoPackage from "../../components/info-package";
import CardForm from "../../components/card-form";
import KeyKzCountryImage from "../../components/key-cz-country-image";
import DeliveryInfoSection from "../../components/delivery-info-section";
import FormGroup from "../../components/form-group";
import Checkbox from "../../components/checkbox";
import Underline from "../../components/undreline";
import Radio from "../../components/radio";
import CustomerAddress from "../../components/forms/customer-information/customer-information";
import AddresseeInformation from "../../components/forms/addressee-information/addressee-information";
import {
  NOT_CHOOSEN_PICKUP_ADDRESS,
  NOT_CHOOSE_DELIVERY_ADDRESS,
} from "../../constants/error-messages";
import { createOrder } from "../../services/orderService";
import {
  _getFormatPrice,
  isItAWeekEndDay,
  getNextWeekDay,
  formatDate,
  typeFormFromLocation,
  determineTimeDelivery,
  createAditionalPrice,
} from "src/helpers/additionalFuctions";
import {
  PAYMENT_TYPE_ERROR,
  TERMS_ERROR,
  ANY_ERROR,
  NOT_CHOOSEN_TIME_PICKUP,
  NOT_SENT_ORDER_BY_EMAIL,
} from "../../constants/error-messages";
import {
  BASE_PRICE_KE_KE,
  EXTRA_PRICE_KE_KE,
  ExtraPrice_Pripadkova_Zone,
} from "../../constants/price";
import { HOUR_TO_PICKUP } from "src/constants/ke_cz";
import "aos/dist/aos.css";
import { ANIMATION_TYPE } from "../../constants/animation";
import AOS from "aos";
import { setLastCompantToStorage } from "../../services/las_company.service";
import { navigate } from "gatsby";
import {
  INFO_PACKAGE,
  KEY_ACCEPTED_COOKIES,
  PRIPADKOVA_ZONA,
} from "../../constants/homepage";
import { getAcceptedCookie } from "../../services/acceptedCookie";
import CookieBanner from "../../components/cookie-banner/index";

const KeKeFormPage = ({ location }) => {
  const [isAcceptedCookie, setIsAcceptedCookie] = useState(
    getAcceptedCookie(KEY_ACCEPTED_COOKIES)
  );
  const {
    register,
    control,
    handleSubmit,
    errors,
    setValue,
    getValues,
  } = useForm();
  const currentTime = new Date();
  const pickupAddressCustomerRef = useRef(null);
  const deliveryAddressCustomerRef = useRef(null);
  const [isChangedAddressCustomer, setIsChangedAddressCustomer] = useState(
    false
  );
  const [errorSendEmail, serErrorSendEmail] = useState("");
  const [isChangedAddressAddresse, setIsChangedAddressAddresse] = useState(
    false
  );
  const [errorNotChoosenTimePickup, setErrorNotChoosenTimePickup] = useState(
    ""
  );
  const [
    errorIsNotchoosenPickupAddress,
    setErrorIsNotchoosenPickupAddress,
  ] = useState("");
  const [
    errorisNotChoosenDeliveryAddress,
    setErrorisNotChoosenDeliveryAddress,
  ] = useState("");
  const typeForm = typeFormFromLocation(location.pathname.replace("/", ""));
  const [isNeededMultiply, setIsNeededMultiply] = useState(true);
  const [additionalPrice, setAdditionalPrice] = useState({
    express: {
      active: false,
      extraprice: EXTRA_PRICE_KE_KE,
    },
    pripadkovaZonePickup: {
      active: false,
      extraprice: ExtraPrice_Pripadkova_Zone,
    },
    pripadkovaZoneDelivery: {
      active: false,
      extraprice: ExtraPrice_Pripadkova_Zone,
    },
    dayOff: {
      active: false,
      extraprice: EXTRA_PRICE_KE_KE,
    },
    extraTime: {
      active: false,
      extraprice: EXTRA_PRICE_KE_KE,
    },
  });
  const datePickerRef = useRef(null);

  const Submit = async (values) => {
    if (!values.deliveryTime) {
      window.scrollTo({
        top: datePickerRef.current.offsetTop,
        behavior: "smooth",
      });
      setErrorNotChoosenTimePickup(NOT_CHOOSEN_TIME_PICKUP);
      return;
    }

    if (!pickupAddress() || !deliveryAddress()) {
      window.scrollTo({
        top: !pickupAddress()
          ? pickupAddressCustomerRef.current.offsetTop
          : deliveryAddressCustomerRef.current.offsetTop,
        behavior: "smooth",
      });
      !pickupAddress()
        ? setErrorIsNotchoosenPickupAddress(NOT_CHOOSEN_PICKUP_ADDRESS)
        : setErrorisNotChoosenDeliveryAddress(NOT_CHOOSE_DELIVERY_ADDRESS);
      return;
    }
    const lastCompanyInformation = {
      companyName: values.companyName,
      companyZipCode: values.companyZipCode,
      companyTaxNumber: values.companyTaxNumber,
      companyRegistrationCode: values.companyRegistrationCode,
    };
    if (
      lastCompanyInformation.companyName &&
      getAcceptedCookie(KEY_ACCEPTED_COOKIES) !== false
    ) {
      setLastCompantToStorage(lastCompanyInformation, typeForm);
    }
    try {
      const additinalDataOfOrder = {
        deliveryAddress: deliveryAddress(),
        pickupAddress: pickupAddress(),
        sum: _getFormatPrice(costSum(), true),
        additionalPrice: createAditionalPrice(additionalPrice),
      };
      const complitedOred = await createOrder(values, additinalDataOfOrder);
      await navigate("/thank-you", {
        state: complitedOred.complitedOrder,
      });
    } catch (error) {
      serErrorSendEmail(error);
    }
  };
  const delivery = BASE_PRICE_KE_KE;

  const pickupAddress = () => {
    if (!location.state || !location.state.pickupAddress) {
      return null;
    }
    return location.state.pickupAddress;
  };

  const deliveryAddress = () => {
    if (!location.state || !location.state.deliveryAddress) {
      return null;
    }
    return location.state.deliveryAddress;
  };

  useEffect(() => {
    if (pickupAddress && deliveryAddress) {
      setIsChangedAddressAddresse(false);
      setIsChangedAddressCustomer(false);
      const _pickupAddress = pickupAddress();
      const _deliveryAddress = deliveryAddress();
      if (
        _pickupAddress &&
        _deliveryAddress &&
        PRIPADKOVA_ZONA.includes(_pickupAddress.geoCode.postcode) &&
        PRIPADKOVA_ZONA.includes(_deliveryAddress.geoCode.postcode)
      ) {
        setAdditionalPrice({
          ...additionalPrice,
          pripadkovaZoneDelivery: {
            active: true,
            extraprice: ExtraPrice_Pripadkova_Zone,
          },
          pripadkovaZonePickup: {
            active: true,
            extraprice: ExtraPrice_Pripadkova_Zone,
          },
        });
      } else if (
        _pickupAddress &&
        PRIPADKOVA_ZONA.includes(_pickupAddress.geoCode.postcode)
      ) {
        setAdditionalPrice({
          ...additionalPrice,
          pripadkovaZonePickup: {
            active: true,
            extraprice: ExtraPrice_Pripadkova_Zone,
          },
          pripadkovaZoneDelivery: {
            active: false,
          },
        });
      } else if (
        _deliveryAddress &&
        PRIPADKOVA_ZONA.includes(_deliveryAddress.geoCode.postcode)
      ) {
        setAdditionalPrice({
          ...additionalPrice,
          pripadkovaZoneDelivery: {
            active: true,
            extraprice: ExtraPrice_Pripadkova_Zone,
          },
          pripadkovaZonePickup: {
            active: false,
          },
        });
      } else {
        setAdditionalPrice({
          ...additionalPrice,
          pripadkovaZonePickup: {
            active: false,
          },
          pripadkovaZoneDelivery: {
            active: false,
          },
        });
      }
    }
  }, [isChangedAddressCustomer, isChangedAddressAddresse]);

  const costSum = () => {
    let sum = BASE_PRICE_KE_KE / 100;
    for (let key in additionalPrice) {
      if (additionalPrice[key].active) {
        sum = sum + additionalPrice[key].extraprice;
      }
    }
    return sum;
  };

  const getErrorMessage = () => {
    if (errors.agreement) {
      return TERMS_ERROR;
    } else if (errors.paymentType) {
      return PAYMENT_TYPE_ERROR;
    }
    return ANY_ERROR;
  };
  const getDeliveryLabel = () => {
    if (isItAWeekEndDay()) {
      return {
        color: "grey",
        label: formatDate(getNextWeekDay(1)),
      };
    }
    return {
      color: currentTime.getHours() < HOUR_TO_PICKUP - 1 ? "green" : "grey",
      label: currentTime.getHours() < HOUR_TO_PICKUP - 1 ? "Dnes" : "Zítra",
    };
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Page>
      <div className={classNames.container}>
        <Header hiddenContact />
        <GoHome />
        <div
          className={classnames(classNames.title, classNames.container)}
          data-aos={ANIMATION_TYPE}
        >
          <h1 className={classNames.textTitle}>
            Doručenie v&nbsp;rámci&nbsp;Košíc
          </h1>
          <Button
            hidden="hidden"
            className={classNames.button}
            color={`${getDeliveryLabel().color}`}
          >
            {getDeliveryLabel().label}.
          </Button>
          <div className={classNames.titlePrice}>
            Doručenie od<b> {_getFormatPrice(BASE_PRICE_KE_KE)} €</b>
          </div>
        </div>

        <form
          onSubmit={handleSubmit(Submit)}
          className={classNames.countryContainer}
          data-aos={ANIMATION_TYPE}
          onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
          }}
        >
          <FormProvider
            register={register}
            errors={errors}
            control={control}
            setValue={setValue}
            getValues={getValues}
          >
            <div className={classNames.leftPath}>
              <div className={classNames.rectangleOne}>
                <InfoPackage infoPackage={INFO_PACKAGE.ke_ke} />
              </div>
              <div className={classNames.desktop} ref={datePickerRef}>
                <div className={classNames.rectangleOne}>
                  <KeKeDateSelector
                    setAdditionalPrice={setAdditionalPrice}
                    additionalPrice={additionalPrice}
                    errorNotChoosenTimePickup={errorNotChoosenTimePickup}
                    setErrorNotChoosenTimePickup={setErrorNotChoosenTimePickup}
                  />
                </div>
              </div>

              <div>
                <div className={classNames.container}>
                  <div className={classNames.rectangleOne}>
                    <CardForm className={classNames.cardForm}>
                      <div ref={pickupAddressCustomerRef}>
                        <CustomerAddress
                          register={register}
                          typeForm={typeForm}
                          presetAddress={pickupAddress()}
                          setAdditionalPrice={setAdditionalPrice}
                          additionalPrice={additionalPrice}
                          pripadkovaZonePickup={
                            additionalPrice.pripadkovaZonePickup.active
                          }
                          setIsChangedAddressCustomer={
                            setIsChangedAddressCustomer
                          }
                          location={location}
                          errors={errors}
                          errorIsNotchoosenPickupAddress={
                            errorIsNotchoosenPickupAddress
                          }
                          setErrorIsNotchoosenPickupAddress={
                            setErrorIsNotchoosenPickupAddress
                          }
                        />
                      </div>
                    </CardForm>
                  </div>
                </div>
              </div>

              <div className={classNames.container}>
                <div className={classNames.rectangleOne}>
                  <CardForm className={classNames.cardForm}>
                    <div ref={deliveryAddressCustomerRef}>
                      <AddresseeInformation
                        register={register}
                        presetAddress={deliveryAddress()}
                        pripadkovaZoneDelivery={
                          additionalPrice.pripadkovaZoneDelivery.active
                        }
                        location={location}
                        setIsChangedAddressAddresse={
                          setIsChangedAddressAddresse
                        }
                        isChangedAddressAddresse={isChangedAddressAddresse}
                        errors={errors}
                        errorisNotChoosenDeliveryAddress={
                          errorisNotChoosenDeliveryAddress
                        }
                        typeForm={typeForm}
                        setErrorisNotChoosenDeliveryAddress={
                          setErrorisNotChoosenDeliveryAddress
                        }
                      />
                    </div>
                  </CardForm>
                </div>
              </div>

              <div className={classNames.container}>
                <CardForm className={classNames.rectangleOne}>
                  <div className={classNames.innerFrame}>
                    <span className={classNames.labelPay}>
                      Vyberte spôsob platby:
                    </span>
                    <div className={classNames.choosePayFirstChild}>
                      <Radio
                        register={register}
                        id="paymentType1"
                        value="1"
                        name="paymentType"
                        errors={errors}
                      />
                      <span>
                        Kuriérovi pri vyzdvihnutí (kartou alebo v hotovosti)
                      </span>
                    </div>
                    <div className={classNames.choosePay}>
                      <Radio
                        register={register}
                        id="paymentType2"
                        value="2"
                        name="paymentType"
                        errors={errors}
                      />
                      <span>
                        Kuriérovi pri doručení (kartou alebo v hotovosti)
                      </span>
                    </div>
                    <hr />
                    <span>
                      Doručenie v rámci Košic {_getFormatPrice(delivery)} €
                    </span>

                    {additionalPrice.pripadkovaZonePickup.active ? (
                      <span>
                        Adresa v príplatkovej zóne +
                        {_getFormatPrice(
                          additionalPrice.pripadkovaZonePickup.extraprice,
                          isNeededMultiply
                        )}
                        €
                      </span>
                    ) : (
                      ""
                    )}
                    {additionalPrice.pripadkovaZoneDelivery.active ? (
                      <span>
                        Adresa v príplatkovej zóne +
                        {_getFormatPrice(
                          additionalPrice.pripadkovaZoneDelivery.extraprice,
                          isNeededMultiply
                        )}
                        €
                      </span>
                    ) : (
                      ""
                    )}
                    {additionalPrice.dayOff.active ? (
                      <span>
                        Víkendové doručenie +
                        {_getFormatPrice(
                          additionalPrice.dayOff.extraprice,
                          isNeededMultiply
                        )}
                        €
                      </span>
                    ) : (
                      ""
                    )}
                    {additionalPrice.extraTime.active ? (
                      <span>
                        Príplatok +
                        {_getFormatPrice(
                          additionalPrice.extraTime.extraprice,
                          isNeededMultiply
                        )}
                        €
                      </span>
                    ) : (
                      ""
                    )}
                    {additionalPrice.express.active ? (
                      <span>
                        Express +
                        {_getFormatPrice(
                          additionalPrice.express.extraprice,
                          isNeededMultiply
                        )}
                        €
                      </span>
                    ) : (
                      ""
                    )}
                    <span className={classNames.total}>
                      Spolu: {_getFormatPrice(costSum(), isNeededMultiply)} €
                    </span>
                  </div>
                </CardForm>
              </div>

              <FormGroup className={classNames.formGroup}>
                <div className={classNames.checkboxMobile}>
                  <div>
                    <Checkbox
                      register={register}
                      className={classNames.checkbox}
                      id="agreement"
                    >
                      Súhlasím s{" "}
                      <Underline>
                        Obchodnými podmienkami{" "}
                        <span className={classNames.mobileDisplayNone}>
                          Doruč.to
                        </span>
                      </Underline>
                    </Checkbox>
                  </div>
                </div>
              </FormGroup>
              {Object.values(errors).length > 0 && (
                <div className={classNames.errorWrap}>{getErrorMessage()}</div>
              )}

              <div className={classNames.buttonTwo}>
                <p className={classNames.errorSendEmail}>{errorSendEmail}</p>
                <Button
                  type="submit"
                  color="green"
                  disabled={Object.values(errors).length > 0}
                >
                  Objednať
                </Button>
              </div>
            </div>
            <div className={classNames.rightPath}>
              <div>
                <KeyKzCountryImage />
              </div>
              <div className={classNames.deliverySection}>
                <DeliveryInfoSection braces="left" border />
              </div>
            </div>
          </FormProvider>
        </form>
        <Footer />
      </div>
      {isAcceptedCookie === null ? (
        <CookieBanner setIsAcceptedCookie={setIsAcceptedCookie} />
      ) : (
        ""
      )}
    </Page>
  );
};

export default KeKeFormPage;
